import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Fetch_Url } from "../../Context/Context";

export default function EarningDetails() {
  const navigate = useNavigate();
  const [earndata, setEarndata] = useState([]);

  const getEarnInfo = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_bill_fetch_today",
      })
      .then((resp) => {
        console.log(resp, "resp getEarnInfo data");
        setEarndata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEarnInfo();
  }, []);

  return (
    <>
      <div className="backBtn">
        <i
          className="fa fa-angle-left"
          onClick={() => {
            navigate("/");
          }}
        ></i>
      </div>
      <div className="earnTable">
        <table>
          <thead>
            <tr>
              <th>Invoice No.</th>
              <th>Table No.</th>
              <th>CGST</th>
              <th>SGST</th>
              <th>Total Amount</th>
              <th>Cabin Charges</th>
              <th>Grand Total</th>
              <th>Time</th>
              <th>Payment Status</th>
              <th>Served By</th>
            </tr>
          </thead>
          <tbody>
            {earndata?.map((item) => {
              return (
                <>
                  <tr
                    onClick={() => {
                      navigate("/allearning", { state: item });
                    }}
                  >
                    <td>{item?.invoice_id}</td>
                    <td>{item?.table_id}</td>
                    <td>
                      <i className="fa fa-rupee"></i>
                      {item?.cgst}
                    </td>
                    <td>
                      <i className="fa fa-rupee"></i>
                      {item?.sgst}
                    </td>
                    <td>
                      <i className="fa fa-rupee"></i>
                      {item?.total_amount}
                    </td>
                    <td>
                      <i className="fa fa-rupee"></i>
                      {item?.cabin_charge}
                    </td>
                    <td>
                      <i className="fa fa-rupee"></i>
                      {+item?.grand_total + +item?.cabin_charge}
                    </td>
                    <td>
                      {item?.check_out_time}
                    </td>
                    <td>{item?.payment_status}</td>
                    <td>{item?.ServedBy}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
