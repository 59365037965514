import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Fetch_Url } from "../../../Context/Context";

export default function CategoryDetails() {
  const location = useLocation();
  const stateData = location?.state;
  console.log(stateData, "statedata");

  const navigate = useNavigate();
  const [alldata, setAlldata] = useState([]);

  const getCatInfo = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_expenses_by_category",
        CategoryId: stateData,
      })
      .then((resp) => {
        console.log(resp, "resp getCatInfo data");
        setAlldata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCatInfo();
  }, [stateData]);

  return (
    <>
      <div className="backBtn">
        <i
          className="fa fa-angle-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
      </div>
      <div className="earnTable">
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Item</th>
              <th>Price</th>
              <th>Remarks</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {alldata?.map((item) => {
              return (
                <>
                  <tr>
                    <td>{item?.ExpenseCategory}</td>
                    <td>{item?.ItemName}</td>
                    <td>
                      <i className="fa fa-rupee"></i>
                      {item?.Price}
                    </td>
                    <td>{item?.Remark}</td>
                    <td>{item?.Date}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
