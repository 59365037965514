import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Fetch_Url } from "../../Context/Context";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);

  const login = () => {
    email?.length > 0 && password?.length > 0
      ? axios
          .post(`${Fetch_Url}/restaurant_api.php`, {
            aksi: "proses_user_login",
            UserEmail: email,
            UserPassword: password,
          })
          .then((resp) => {
            console.log(resp, "login resp data");
            if (resp?.data?.success === true) {
              localStorage.setItem(
                "loginData",
                JSON.stringify(resp?.data?.result)
              );
              navigate("/");
            } else {
              toast.error(resp?.data?.msg, {    
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Please Fill Details", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  return (
    <>
      <ToastContainer />
      <div className="foodHead">
        <div className="foodImg">
          <img src="http://bullbitescafe.com/its_admin/img/logo2.jpg" alt="" />
        </div>
      </div>
      <div className="loginBox">
        <div className="loginInput">
          <label>Email</label>
          <input
            type="text"
            placeholder="Enter Your Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="loginInput">
          <label>Password</label>
          <input
            type="text"
            placeholder="Enter Your Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <div className="loginBtn">
          <button onClick={login}>Sign In</button>
        </div>
      </div>
    </>
  );
}

/* Login CSS */
