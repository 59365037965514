import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Fetch_Url } from "../../Context/Context";
import audio from "../KotOrders/ringtone-193209.mp3";
import { Howl } from "howler";

export default function KotOrders() {
  const [kotinfo, setKotinfo] = useState([]);
  const prevDataLengthRef = useRef(0);

  const getKOTInfo = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_kitechnlist",
        kot_request: "making",
      })
      .then((resp) => {
        console.log(resp, "resp getKOTInfo data");
        setKotinfo(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateKOT = (itemId) => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_update_cart_bykiten",
        id: itemId,
        kot_request: "Maked",
      })
      .then((resp) => {
        console.log(resp, "resp updateKOT data");
        resp?.data?.success && getKOTInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const playNotificationSound = () => {
    const sound = new Howl({
      src: [audio],
    });
    sound.play();
  };

  useEffect(() => {
    if (kotinfo?.length > prevDataLengthRef?.current) {
      playNotificationSound();
    }
    prevDataLengthRef.current = kotinfo?.length;
  }, [kotinfo]);

  useEffect(() => {
    getKOTInfo();
  }, []);

  setTimeout(() => {
    getKOTInfo();
  }, 10000);

  return (
    <>
      <div className="kotHead">
        <h3>KOT Orders</h3>
      </div>
      <div className="kotTable">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Table No.</th>
              <th>Menu Item</th>
              <th>Quantity</th>
              <th>KOT Status</th>
              <th>Date Time</th>
              <th>Served By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {kotinfo?.map((item) => {
              return (
                <>
                  <tr style={{ background: item?.bg_color }}>
                    <td>{item?.srno}</td>
                    <td>{item?.table_id}</td>
                    <td>{item?.menu_id}</td>
                    <td>{item?.quantity}</td>
                    <td>{item?.kot_status}</td>
                    <td>{item?.date_time}</td>
                    <td>{item?.served_by}</td>
                    <td>
                      <button
                        onClick={() => {
                          updateKOT(item?.id);
                        }}
                      >
                        {item?.kot_action}
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

/* KotOrders CSS */
