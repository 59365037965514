import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Fetch_Url } from "../../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// mui
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Dashboard() {
  const location = useLocation();
  const stateData = location?.state;
  console.log(stateData, "stateData");

  const navigate = useNavigate();
  const [catdata, setCatdata] = useState([]);
  const [prodata, setProdata] = useState([]);
  const [cartdata, setCartdata] = useState([]);
  const [kotdata, setKotdata] = useState([]);
  const [catid, setCatid] = useState([]);
  const [discountType, setDiscountType] = useState([]);
  const [discountPercent, setDiscountPercent] = useState("");
  const [discountvalue, setDiscountValue] = useState("");
  const [remarks, setRemarks] = useState("");
  const [custname, setCustname] = useState("");
  const [custnumber, setCustnumber] = useState("");
  const [selectpay, setSelectpay] = useState([]);
  const [value, setValue] = React.useState(0);
  const [searchinput, setSearchinput] = useState("");
  const [mandata, setMandata] = useState([]);
  const [cabin, setCabin] = useState("");
  const [selectman, setSelectman] = useState(stateData?.served_by);
  // const [invoiceNum, setInvoiceNum] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCategory = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_category",
      })
      .then((resp) => {
        console.log(resp, "resp getCategory data");
        setCatdata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProducts = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_menu",
        category_id: catid?.length === 0 ? "0" : catid,
      })
      .then((resp) => {
        console.log(resp, "resp getProducts data");
        setProdata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCart = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_cart",
        table_id: stateData?.table_id,
        order_status: "Pending",
      })
      .then((resp) => {
        console.log(resp, "resp getCart data");
        setCartdata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getKot = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_cart",
        table_id: stateData?.table_id,
        order_status: "KOT",
      })
      .then((resp) => {
        console.log(resp, "resp getKot data");
        setKotdata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getServiceman = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_staff",
      })
      .then((resp) => {
        console.log(resp, "resp getServiceman data");
        setMandata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postProducts = (Item) => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_add_cart",
        table_id: stateData?.table_id,
        menu_id: Item?.id,
        current_price: Item?.Current_price,
      })
      .then((resp) => {
        console.log(resp, "resp postProducts data");
        resp?.data?.success && getCart();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const incrementQuantity = (id) => {
    setKotdata(
      kotdata?.map((item) => {
        if (item?.id === id) {
          return { ...item, quantity: +item?.quantity + 1 };
        }
        return item;
      })
    );
  };

  const decrementQuantity = (id) => {
    setKotdata(
      kotdata?.map((item) => {
        if (item?.id === id && item?.quantity > 0) {
          return { ...item, quantity: +item?.quantity - 1 };
        }
        return item;
      })
    );
  };

  const updateQuantity = (quantity, Item) => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_update_cart_kot",
        id: Item?.id,
        quantity: quantity,
        current_price: Item?.current_price,
        table_id: stateData?.table_id,
      })
      .then((resp) => {
        console.log(resp, "resp updateQuantity data");
        resp?.data?.success && getCart();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postQuantity = (Item) => {
    kotdata?.map((item) => {
      if (item?.id === Item?.id) {
        // console.log(Item, "item");

        axios
          .post(`${Fetch_Url}/restaurant_api.php`, {
            aksi: "proses_update_kot_cart",
            id: Item?.id,
            quantity: Item?.quantity,
            current_price: Item?.current_price,
            table_id: stateData?.table_id,
          })
          .then((resp) => {
            console.log(resp, "resp postQuantity data");
            if (resp?.data?.success === true) {
              toast.success("Quantity Update Successfully", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              getKot();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const deleteCart = (itemId) => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_delete_cart",
        id: itemId,
      })
      .then((resp) => {
        console.log(resp, "resp deleteCart data");
        if (resp?.data?.success === true) {
          getCart();
          getKot();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postKOT = () => {
    selectman?.length > 0
      ? axios
          .post(`${Fetch_Url}/restaurant_api.php`, {
            aksi: "proses_kot_print",
            table_id: stateData?.table_id,
            ServedBy: selectman,
          })
          .then((resp) => {
            console.log(resp, "resp postKOT data");
            if (resp?.data?.success === true) {
              getCart();
              getKot();
            }
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Please Select Serviceman", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  let totalPrice = 0;
  kotdata?.map((item) => (totalPrice += +item?.total_price));
  // console.log(totalPrice, "totalPrice");

  let sgst = (totalPrice * 2.5) / 100;
  // console.log(sgst, "sgst");

  let cgst = (totalPrice * 2.5) / 100;
  // console.log(cgst, "cgst");

  let Grandtotal = totalPrice + sgst + cgst;
  // console.log(Grandtotal, "Grandtotal");

  let findDiscount = (Grandtotal * discountPercent) / 100;
  // console.log(findDiscount, "findDiscount");

  let finalAmount = Grandtotal + +cabin - discountvalue - findDiscount;
  // console.log(finalAmount, "finalAmount");

  const printOrder = () => {
    selectpay?.length > 0
      ? selectman?.length > 0
        ? axios
            .post(`${Fetch_Url}/restaurant_api.php`, {
              aksi: "proses_add_billingOrder",
              table_id: stateData?.table_id,
              sgst: sgst,
              cgst: cgst,
              discount_amount:
                discountType === "Flat" ? discountvalue : discountPercent,
              remark: remarks,
              cabin_charge: cabin,
              check_out_time: `${removeHour}:${removeMin}`,
              payment_method: selectpay,
              total_amount: totalPrice,
              paid_amount: finalAmount,
            })
            .then((resp) => {
              console.log(resp, "resp printOrder data");
              const invoiceId = resp?.data?.Invoice_id?.invoice_id;
              const serviceMan = resp?.data?.ServedBy?.name;
              resp?.data?.success &&
                navigate("/invoice", {
                  state: {
                    custname,
                    custnumber,
                    selectpay,
                    totalPrice,
                    sgst,
                    cgst,
                    removeHour, 
                    removeMin,
                    cabin,
                    discountType,
                    discountvalue,
                    discountPercent,
                    finalAmount,
                    stateData,
                    invoiceId,
                    serviceMan,
                  },
                });
            })
            .catch((err) => {
              console.log(err);
            })
        : toast.error("Please Select Serviceman", {
            position: toast.POSITION.BOTTOM_LEFT,
          })
      : toast.error("Please Select Payment Method", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  const searchBar = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_sub_menu_for_search_bar",
        SubMenuName: searchinput,
      })
      .then((resp) => {
        console.log(resp, "resp searchBar data");
        setProdata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCategory();
    getCart();
    getKot();
    getServiceman();
  }, []);

  useEffect(() => {
    getProducts();
  }, [catid]);

  useEffect(() => {
    searchBar();
  }, [searchinput]);

  const date = new Date();
  let getHour= date?.getHours()
  let getMinutes= date?.getMinutes()
  // console.log(getHour, getMinutes, "getHour getMinutes")

  let findHour= (stateData?.order_time)?.slice(0, 2)
  // console.log(findHour, "findHour")

  let findMin= (stateData?.order_time)?.slice(3, 5)
  // console.log(findMin, "findSec")

  let removeHour= getHour - findHour
  // console.log(removeHour, "removeHour")

  let removeMin= getMinutes - findMin
  // console.log(removeMin, "removeMin")

  // const createInvoice = () => {
  //   setInvoiceNum(Math?.floor(100000 + Math?.random() * 900000));
  // };

  //  useEffect(() => {
  //    invoiceNum > 0 && printOrder();
  //  }, [invoiceNum]);

  // const [baseNumber, setBaseNumber] = useState(() => {
  //   const storedBaseNumber = localStorage.getItem("baseNumber");
  //   return storedBaseNumber ? parseInt(storedBaseNumber, 10) : null;
  // });
  // const [serialNumber, setSerialNumber] = useState("");

  // useEffect(() => {
  //   if (baseNumber !== null) {
  //     setSerialNumber(baseNumber.toString().padStart(5, "0"));
  //   }
  // }, [baseNumber]);

  // const generateRandomNumber = () => {
  //   const newBaseNumber = Math.floor(10000 + Math.random() * 90000); // Generate a random 5-digit number
  //   setBaseNumber(newBaseNumber);
  //   localStorage.setItem("baseNumber", newBaseNumber.toString());
  // };

  // const generateSerialNumber = () => {
  //   if (baseNumber === null) {
  //     generateRandomNumber();
  //   } else {
  //     const newSerialNumber = parseInt(serialNumber) + 1;
  //     setSerialNumber(newSerialNumber.toString().padStart(5, "0"));
  //     localStorage.setItem("baseNumber", newSerialNumber.toString());
  //   }
  // };

  return (
    <>
      <ToastContainer />
      <div className="foodHead">
        <div className="foodImg">
          <img src="http://bullbitescafe.com/its_admin/img/logo2.jpg" alt="" />
        </div>
        <div className="foodInfo">
          <h4>{stateData?.table_no}</h4>
          <h4>Time- {removeHour}:{removeMin}</h4>
        </div>
        <div className="foodBtn">
          <h4>
            Total Amount : <i className="fa fa-rupee"></i>
            {totalPrice?.toFixed()}
          </h4>
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            Dashboard
          </button>
        </div>
      </div>
      <div className="dashGrid">
        <div className="alldashCat">
          {catdata?.map((item) => {
            return (
              <>
                <div
                  className="dashCat"
                  onClick={() => {
                    setCatid(item?.id);
                  }}
                >
                  <h4>{item?.category_name}</h4>
                </div>
              </>
            );
          })}
        </div>
        <div>
          <div className="searchInput">
            <input
              type="text"
              placeholder="Search Here"
              onChange={(e) => {
                setSearchinput(e.target.value);
              }}
            />
          </div>
          <div className="alldashPro">
            {prodata?.map((item) => {
              return (
                <>
                  <div
                    className="dashPro"
                    onClick={() => {
                      postProducts(item);
                    }}
                  >
                    <h4>{item?.item_name}</h4>
                    <h5>
                      <i className="fa fa-rupee"></i>
                      {item?.Current_price}
                    </h5>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="alldashDetails">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Orders" />
              <Tab label="KOT" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="dashTxt">
              <h5>Items</h5>
              <h5>Qty.</h5>
              <h5>Price</h5>
            </div>
            <div className="orderdashItems">
              {cartdata?.map((item) => {
                return (
                  <>
                    <div className="dashItems">
                      <div className="dashInfo">
                        <h5>
                          <i
                            className="fa fa-close"
                            onClick={() => {
                              deleteCart(item?.id);
                            }}
                          ></i>
                          {item?.item_name}
                        </h5>
                      </div>
                      <div className="dashQty">
                        <button
                          onClick={() => {
                            updateQuantity(+item?.quantity + 1, item);
                          }}
                        >
                          +
                        </button>
                        <h5>{item?.quantity}</h5>
                        <button
                          disabled={item?.quantity <= 1}
                          onClick={() => {
                            updateQuantity(+item?.quantity - 1, item);
                          }}
                        >
                          -
                        </button>
                      </div>
                      <div className="dashPrice">
                        <i className="fa fa-rupee"></i>
                        <h5>{item?.current_price * item?.quantity}</h5>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="dashBot">
              {selectman?.length === 0 && (
                <select
                  onChange={(e) => {
                    setSelectman(e.target.value);
                  }}
                >
                  <option selected hidden>
                    Serviceman
                  </option>
                  {mandata?.map((item) => {
                    return (
                      <>
                        <option value={item?.id}>{item?.Name}</option>
                      </>
                    );
                  })}
                </select>
              )}
              <div className="dashBtn">
                <button onClick={postKOT}>KOT & Print</button>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="dashTxt">
              <h5>Items</h5>
              <h5>Qty.</h5>
              <h5>Price</h5>
            </div>
            <div className="alldashItems">
              {kotdata?.map((item) => {
                return (
                  <>
                    <div className="dashItems">
                      <div className="dashInfo">
                        <h5>
                          <i
                            className="fa fa-close"
                            onClick={() => {
                              deleteCart(item?.id);
                            }}
                          ></i>
                          {item?.item_name}
                        </h5>
                      </div>
                      <div className="dashQty">
                        <button
                          onClick={() => {
                            incrementQuantity(item?.id);
                          }}
                        >
                          +
                        </button>
                        <h5>{item?.quantity}</h5>
                        <button
                          disabled={item?.quantity <= 1}
                          onClick={() => {
                            decrementQuantity(item?.id);
                          }}
                        >
                          -
                        </button>
                        <i
                          className="fa fa-check"
                          onClick={() => {
                            postQuantity(item);
                          }}
                        ></i>
                      </div>
                      <div className="dashPrice">
                        <i className="fa fa-rupee"></i>
                        <h5>{item?.current_price * item?.quantity}</h5>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="dashBot">
              <div className="dashData">
                <div className="dashRemarks">
                  <select
                    onChange={(e) => {
                      setDiscountType(e.target.value);
                    }}
                  >
                    <option selected hidden>
                      Discount
                    </option>
                    <option value="Flat">Flat</option>
                    <option value="Percent">Percent</option>
                  </select>
                  {discountType?.length > 0 && (
                    <input
                      type="text"
                      placeholder="Enter Remarks"
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                    />
                  )}
                  <input type="number" placeholder="Enter Cabin Charge" onChange={(e)=>{setCabin(e.target.value)}}/>
                </div>
                <div className="dashPayment">
                  <h5>
                    Total Amount- <i className="fa fa-rupee"></i> {totalPrice}
                  </h5>
                  <h5>
                    SGST (2.5%)- <i className="fa fa-rupee"></i> {sgst}
                  </h5>
                  <h5>
                    CGST (2.5%)- <i className="fa fa-rupee"></i> {cgst}
                  </h5>
                  {discountType?.length > 0 && (
                    <label>
                      Discount-
                      {discountType === "Flat" ? (
                        <input
                          type="number"
                          placeholder="Discount"
                          value={
                            discountvalue <= Grandtotal
                              ? discountvalue
                              : setDiscountValue([])
                          }
                          onChange={(e) => {
                            setDiscountValue(e.target.value);
                          }}
                        />
                      ) : (
                        <input
                          type="number"
                          placeholder="Percent"
                          value={
                            discountPercent <= 100
                              ? discountPercent
                              : setDiscountPercent([])
                          }
                          onChange={(e) => {
                            setDiscountPercent(e.target.value);
                          }}
                        />
                      )}
                    </label>
                  )}
                  <h4>
                    Grand Total- <i className="fa fa-rupee"></i>
                    {finalAmount?.toFixed()}
                  </h4>
                </div>
              </div>
              <div className="dashInput">
                <input
                  type="text"
                  placeholder="Enter Customer Name"
                  onChange={(e) => {
                    setCustname(e.target.value);
                  }}
                />
                <input
                  type="text"
                  placeholder="Enter Customer Mobile No."
                  onChange={(e) => {
                    setCustnumber(e.target.value);
                  }}
                />
              </div>
              <div
                className="alldashRadio"
                onChange={(e) => {
                  setSelectpay(e.target.value);
                }}
              >
                <div className="dashRadio">
                  <input
                    type="radio"
                    id="radioBtn1"
                    name="radioBtn"
                    value="Cash"
                  />
                  <label for="radioBtn1">Cash</label>
                </div>
                <div className="dashRadio">
                  <input
                    type="radio"
                    id="radioBtn2"
                    name="radioBtn"
                    value="Card"
                  />
                  <label for="radioBtn2">Card</label>
                </div>
              </div>
              <div className="dashBtn">
                <button onClick={printOrder}>Save & Print</button>
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </>
  );
}

/* Dashboard CSS */
