import axios from "axios";
import { Fetch_Url } from "../../Context/Context";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  bgcolor: "white",
  p: 3,
  boxShadow: 24,
};

export default function Expense() {
  const navigate = useNavigate();
  const [catopen, setCatopen] = useState(false);
  const [expensedata, setExpensedata] = useState([]);
  const [allexpense, setAllexpense] = useState([]);
  const [expensedate, setExpensedate] = useState("");
  const [expensedate2, setExpensedate2] = useState("");
  const [addcat, setAddcat] = useState([]);
  const [allcat, setAllcat] = useState([]);
  const [category, setCategory] = useState([]);
  const [itemname, setItemname] = useState([]);
  const [price, setPrice] = useState([]);
  const [date, setDate] = useState([]);
  const [remarks, setRemarks] = useState([]);

  const getCategory = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "view_expence_category",
      })
      .then((resp) => {
        console.log(resp, "resp getCategory data");
        setAllcat(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getExpense = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_expenses",
      })
      .then((resp) => {
        console.log(resp, "resp getExpense data");
        setExpensedata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDateExpense = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_expenses_date_wise",
        from_date: expensedate,
        to_date: expensedate2,
      })
      .then((resp) => {
        console.log(resp, "resp getDateExpense data");
        setAllexpense(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postCategory = () => {
    addcat?.length > 0
      ? axios
          .post(`${Fetch_Url}/restaurant_api.php`, {
            aksi: "create_expence_category",
            category: addcat,
          })
          .then((resp) => {
            console.log(resp, "resp postCategory data");
            if (resp?.data?.success === true) {
              getCategory();
              toast.success(resp?.data?.result, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            } else {
              toast.error(resp?.data?.result, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Please Fill Category", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  const postExpense = () => {
    category?.length > 0 &&
    itemname?.length > 0 &&
    price?.length > 0 &&
    remarks?.length > 0 &&
    date?.length > 0
      ? axios
          .post(`${Fetch_Url}/restaurant_api.php`, {
            aksi: "proses_add_expenses",
            CateId: category,
            ItemName: itemname,
            Price: price,
            Remark: remarks,
            Date: date,
            ExpenceTitle: "",
            PaymentMode: "",
          })
          .then((resp) => {
            console.log(resp, "resp postExpense data");
            if (resp?.data?.success === true) {
              getExpense();
              getDateExpense();
              toast.success(resp?.data?.msg, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            } else {
              toast.error(resp?.data?.msg, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Please Fill All Details", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  const deleteExpense = (itemId) => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_delete_expense",
        ExpenseId: itemId,
      })
      .then((resp) => {
        console.log(resp, "resp deleteExpense data");
        resp?.data?.success
          ? getExpense()
          : toast.error(resp?.data?.msg, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCategory = (itemId) => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_delete_expence_category",
        id: itemId,
      })
      .then((resp) => {
        console.log(resp, "resp deleteCategory data");
        if (resp?.data?.success === true) {
          getCategory();
          getDateExpense();
        } else {
          toast.error(resp?.data?.result, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCategory();
    getExpense();
  }, []);

  useEffect(() => {
    getDateExpense();
  }, [expensedate, expensedate2]);

  return (
    <>
      <ToastContainer />
      <div className="expenseHead">
        <div className="exHeadfirst">
          <i
            className="fa fa-angle-left"
            onClick={() => {
              navigate(-1);
            }}
          ></i>
          <h4>Expense</h4>
        </div>
        <div>
          <button
            onClick={() => {
              setCatopen(true);
            }}
          >
            Create Category
          </button>
        </div>
        <Modal
          open={catopen}
          onClose={() => {
            setCatopen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="catAdd">
              <h4>Add Category</h4>
              <i
                className="fa fa-close"
                onClick={() => {
                  setCatopen(false);
                }}
              ></i>
            </div>
            <div className="catAddbox">
              <input
                type="text"
                placeholder="Enter Category Name"
                onChange={(e) => {
                  setAddcat(e.target.value);
                }}
              />
              <button onClick={postCategory}>Submit</button>
            </div>
            <div className="catInfo">
              <h3>Your Category</h3>
              {allcat?.map((item) => {
                return (
                  <>
                    <div className="minicatInfo">
                      <h4>
                        <i className="fa fa-circle"></i> {item?.category}
                      </h4>
                      <i
                        className="fa fa-trash"
                        onClick={() => {
                          deleteCategory(item?.id);
                        }}
                      ></i>
                    </div>
                  </>
                );
              })}
            </div>
          </Box>
        </Modal>
      </div>
      <div className="expensedrop">
        <select
          onChange={(e) => {
            setCategory(e.target.value);
          }}
        >
          <option hidden>Select Category</option>
          {allcat?.map((item) => {
            return (
              <>
                <option value={item?.id}>{item?.category}</option>
              </>
            );
          })}
        </select>
        <input
          type="text"
          placeholder="Item Name"
          onChange={(e) => {
            setItemname(e.target.value);
          }}
        />
        <input
          type="number"
          placeholder="Price"
          onChange={(e) => {
            setPrice(e.target.value);
          }}
        />
        <input
          type="date"
          onChange={(e) => {
            setDate(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Remarks"
          onChange={(e) => {
            setRemarks(e.target.value);
          }}
        />
        <button onClick={postExpense}>Submit</button>
      </div>
      <div className="expenseInfo">
        <div className="earnTable">
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Item</th>
                <th>Price</th>
                <th>Remarks</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {expensedata?.map((item) => {
                return (
                  <>
                    <tr>
                      <td>{item?.ExpenseCategory}</td>
                      <td>{item?.ItemName}</td>
                      <td>
                        <i className="fa fa-rupee"></i>
                        {item?.Price}
                      </td>
                      <td>{item?.Remark}</td>
                      <td>{item?.Date}</td>
                      <td>
                        <i
                          className="fa fa-trash"
                          onClick={() => {
                            deleteExpense(item?.id);
                          }}
                        ></i>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="earnTable">
          <div className="dateBox">
            <input
              type="date"
              onChange={(e) => {
                setExpensedate(e.target.value);
              }}
            />
            <input
              type="date"
              onChange={(e) => {
                setExpensedate2(e.target.value);
              }}
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {allexpense?.result?.map((item) => {
                return (
                  <>
                    <tr
                      onClick={() => {
                        navigate("/categorydetails", { state: item?.id });
                      }}
                    >
                      <td>{item?.ExpenseCategory}</td>
                      <td>
                        <i className="fa fa-rupee"></i>
                        {item?.TotalExpenseAmount === null
                          ? "0"
                          : item?.TotalExpenseAmount}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
          <div className="totalAmount">
            <h4>Total Amount</h4>
            <h4>
              <i className="fa fa-rupee"></i>
              {allexpense?.total_expense_amount_of_all_category}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

/* Expense CSS */
