import axios from "axios";
import { Fetch_Url } from "../../Context/Context";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PartyBook() {
  const navigate = useNavigate();
  const [partydata, setPartydata] = useState([]);
  const [partyname, setPartyname] = useState([]);
  const [mobile, setMobile] = useState([]);
  const [amount, setAmount] = useState([]);
  const [advamount, setAdvamount] = useState([]);
  const [persons, setPersons] = useState([]);
  const [date, setDate] = useState([]);
  const [remarks, setRemarks] = useState([]);

  const getParty = () => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_fetch_party",
      })
      .then((resp) => {
        console.log(resp, "resp getParty data");
        setPartydata(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postParty = () => {
    partyname?.length > 0 &&
    mobile?.length > 0 &&
    amount?.length > 0 &&
    advamount?.length > 0 &&
    persons?.length > 0 &&
    date?.length > 0 &&
    remarks?.length > 0
      ? axios
          .post(`${Fetch_Url}/restaurant_api.php`, {
            aksi: "proses_add_party_booking",
            PartyName: partyname,
            MobileNo: mobile,
            Amount: amount,
            AdvancedAmount: advamount,
            QuantityOfPersons: persons,
            Remark: remarks,
            BookingDate: date,
          })
          .then((resp) => {
            console.log(resp, "resp postParty data");
            if (resp?.data?.success === true) {
              getParty();
              toast.success(resp?.data?.msg, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            } else {
              toast.error(resp?.data?.msg, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Please Fill All Details", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  const deleteParty = (itemId) => {
    axios
      .post(`${Fetch_Url}/restaurant_api.php`, {
        aksi: "proses_delete_party",
        PartyId: itemId,
      })
      .then((resp) => {
        console.log(resp, "resp deleteParty data");
        resp?.data?.success
          ? getParty()
          : toast.error(resp?.data?.msg, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getParty();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="expenseHead">
        <div className="exHeadfirst">
          <i
            className="fa fa-angle-left"
            onClick={() => {
              navigate(-1);
            }}
          ></i>
          <h4>Party Booking</h4>
        </div>
      </div>
      <div className="expensedrop">
        <input
          type="text"
          placeholder="Party Name"
          onChange={(e) => {
            setPartyname(e.target.value);
          }}
        />
        <input
          type="number"
          placeholder="Mobile Number"
          onChange={(e) => {
            setMobile(e.target.value);
          }}
        />
        <input
          type="number"
          placeholder="Amount"
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
        <input
          type="number"
          placeholder="Advance Amount"
          onChange={(e) => {
            setAdvamount(e.target.value);
          }}
        />
        <input
          type="number"
          placeholder="Number of Person"
          onChange={(e) => {
            setPersons(e.target.value);
          }}
        />
        <input
          type="date"
          onChange={(e) => {
            setDate(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Remarks"
          onChange={(e) => {
            setRemarks(e.target.value);
          }}
        />
        <button onClick={postParty}>Submit</button>
      </div>
      <div className="expenseInfo">
        <div className="earnTable">
          <table>
            <thead>
              <tr>
                <th>Party Name</th>
                <th>Mobile Number</th>
                <th>Amount</th>
                <th>Advance Amount</th>
                <th>Number Of Person</th>
                <th>Booking Date</th>
                <th>Date</th>
                <th>Remarks</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {partydata?.result?.map((item) => {
                return (
                  <>
                    <tr>
                      <td>{item?.PartyName}</td>
                      <td>{item?.MobileNo}</td>
                      <td>
                        <i className="fa fa-rupee"></i>
                        {item?.Amount}
                      </td>
                      <td>
                        <i className="fa fa-rupee"></i>
                        {item?.AdvancedAmount}
                      </td>
                      <td>{item?.QuantityOfPersons}</td>
                      <td>{item?.BookingDate}</td>
                      <td>{item?.Date}</td>
                      <td>{item?.Remark}</td>
                      <td>
                        <i
                          className="fa fa-trash"
                          onClick={() => {
                            deleteParty(item?.id);
                          }}
                        ></i>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

/* Expense CSS */
